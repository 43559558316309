import React, {useContext} from "react"
import Template from "../components/template"
import "../styles/digitales.css"
import { graphql } from "gatsby"
import {SettingsContext} from "../contexts/settings"
import { translate } from "../../utils/language" 
import {BlogpostDownload} from "../components/BlogpostDownload"
import {BlogpostBildMitText} from "../components/BlogpostBildMitText"
import {BlogpostText} from "../components/BlogpostText"
import {BlogpostGallerie} from "../components/BlogpostGallerie"
import {BlogpostVideo} from "../components/BlogpostVideo"
import {BlogpostExternerInhalt} from "../components/BlogpostExternerInhalt"
import {BlogpostExternerInhaltMitText} from "../components/BlogpostExternerInhaltMitText"

const DigitalesPage = ({data}) => {
    const [settings, ] = useContext(SettingsContext)
    const l = settings.language
    const b = data.strapi.digitale
    return (
        <Template currentPage="digital">
            <section className="end-of-site">
                    <div>
                        <h1>{translate(l, b.Titel_de, b.Titel_en)}</h1>
                        {
                            b.Inhalt.map(blogComponent => {
                                switch (blogComponent.__typename) {
                                    case "Strapi_ComponentDefaultMehrsprachigerDownload":
                                        return <BlogpostDownload data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultBildMitMehrsprachigemText":
                                        return <BlogpostBildMitText data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultMehrsprachigerText":
                                        return <BlogpostText data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultGallerie":
                                        return <BlogpostGallerie data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultVideo":
                                        return <BlogpostVideo data={blogComponent} />;
                                    case "Strapi_ComponentDefaultExternerInhalt":
                                        return <BlogpostExternerInhalt data={blogComponent} />;
                                    case "Strapi_ComponentDefaultExternerInhaltMitText":
                                        return <BlogpostExternerInhaltMitText data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultTrennstrich":
                                        return <div className="mint-line-blogpost" data={blogComponent}/>;
                                }
                            }


                            )
                        }
                        
                    </div>
                </section>
        </Template>
    )
}
    
export default DigitalesPage

export const query = graphql`
    query MyQuery3 {
        strapi {
            digitale {
                Titel_de
                Titel_en
                Inhalt {
                    __typename  
                ... on Strapi_ComponentDefaultMehrsprachigerDownload {
                    Titel_englisch
                    Titel_deutsch
                    Link_deutsch
                    Link_englisch
                    Download_deutsch {
                    url
                    }
                    Download_englisch {
                    url
                    }
                }
                ... on Strapi_ComponentDefaultBildMitMehrsprachigemText {
                    Deutsch
                    Englisch
                    Titel_deutsch
                    Titel_englisch
                    bild {
                        Deutsch
                        Englisch
                        Untertitel_de
                        Untertitel_en
                        Bild {
                        url
                        }
                    }
                }
                ... on Strapi_ComponentDefaultVideo {
                    VideoDatei {
                    url
                    }
                }
                ... on Strapi_ComponentDefaultMehrsprachigerText {
                    Deutsch
                    Englisch
                }
                ... on Strapi_ComponentDefaultGallerie {
                    Bilder {
                    Bild {
                        url
                    }
                    Deutsch
                    Englisch
                    Untertitel_de
                    Untertitel_en
                    }
                }
                ... on Strapi_ComponentDefaultExternerInhalt {
                    URL
                }
                ... on Strapi_ComponentDefaultTrennstrich {
                    id
                }
                ... on Strapi_ComponentDefaultExternerInhaltMitText {
                    Deutsch
                    Englisch
                    URL
                }
            }
            }
        }
    }
`